import { Link } from '@material-ui/core';
import { useState } from 'react';
import PreviewWindow from './PreviewWindow';

function arrayIsEmpty(array) {
  if (!Array.isArray(array)) {
    return false;
  }
  if (array.length == 0) {
    return true;
  }
  return false;
}

const RisksPreviewWindow = (props) => {
  const { value, ids } = props;
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setIsWindowOpen(true);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {arrayIsEmpty(ids) ? (
        value
      ) : (
        <Link href="#" onClick={handleClick}>
          {value}
        </Link>
      )}
      <PreviewWindow
        isWindowOpen={isWindowOpen}
        ids={ids}
        setIsWindowOpen={setIsWindowOpen}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default RisksPreviewWindow;
