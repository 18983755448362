import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { phaseToTimeUnits } from './utils';


export const LongTextFormatter = ({ value }) => {
  return (
    <Tooltip placement="bottom-start" enterDelay={500} leaveDelay={200} title={<span style={{fontSize: 16}}>{value}</span>}>
      <span>{value}</span>
    </Tooltip>)
};

export const PriorityFormatter = ({ value }) => {
  if(value === 'I') {
    return <div style={{fontWeight: 'bold', textAlign: 'center', width: '100%', backgroundColor: 'red', color: 'white'}}>{value}</div>;
  } else if (value === 'II') {
    return <div style={{fontWeight: 'bold', textAlign: 'center', width: '100%', backgroundColor: 'yellow'}}>{value}</div>;
  } else if (value === 'III') {
    return <div style={{fontWeight: 'bold', textAlign: 'center', width: '100%', backgroundColor: 'lime'}}>{value}</div>;
  }

  return <div style={{textAlign: 'center', width: '100%'}}>{value}</div>;
};

export const PriorityIdFormatter = ({ value, id }) => {
  if(value === 'I') {
    return <div style={{fontWeight: 'bold', textAlign: 'center', width: '100%', backgroundColor: 'red', color: 'white'}}>{id}</div>;
  } else if (value === 'II') {
    return <div style={{fontWeight: 'bold', textAlign: 'center', width: '100%', backgroundColor: 'yellow'}}>{id}</div>;
  } else if (value === 'III') {
    return <div style={{fontWeight: 'bold', textAlign: 'center', width: '100%', backgroundColor: 'lime'}}>{id}</div>;
  }

  return <div style={{textAlign: 'center', width: '100%'}}>{value}</div>;
};

export const OvertimeFormatter = ({ value, row }) => {
  let units = " " + phaseToTimeUnits(row.phase);

  return <div align="right">{ value ? value + units : ''}</div>
};

export const OvercostFormatter = ({ value }) => {
  return <div align="right">{ value ? value + " [M€]" : ""}</div>;
};

export const ProbabilityFormatter = ({ value }) => {
  return <div align="right">{ value ? value + " [%]" : ""}</div>;
};
